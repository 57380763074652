import React, { useState } from 'react';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import * as Yup from "yup";
import { registerAsyncThunk } from "../store/slice/authSlice";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ErrorMessage, Field, Form, Formik } from 'formik';

const Registration = () => {
    const dispatch = useDispatch();
    const router = useNavigate();
    const initialValues = {
        name: "",
        email: "",
        password: "",
    };
    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email("Invalid email address")
            .required("Email is required"),

        password: Yup.string().required("Password is required"),
    });
    const handleSubmit = async (values) => {
        dispatch(
            registerAsyncThunk({
                userName: values.name,
                email: values.email,
                password: values.password,
                cb: () => {
                    router("/login");
                },
            })
        );
        console.log(values);
    };




    const [passwordVisible, setPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    return (
        <div>
            <div className='signup_page'>
                <div className='card'>
                    <div className='logo'>
                        <img src='/Images/favicon.ico' className='signup_image' />
                    </div>
                    <div className='signup_content'>
                        <h5>Welcome to  Ytube!</h5>
                        <p>Please sign-up to your account and start the adventure</p>
                    </div>
                    <div>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            <Form >
                                <div className='Input_field'>
                                    <label for="username">Username:</label><br />
                                    <Field
                                        type="text"
                                        name="name"
                                        className="input"
                                        placeholder="name"
                                    />
                                    <ErrorMessage
                                        name="name"
                                        component="div"
                                        style={{ color: "red" }}
                                    />
                                    {/* <input type="text" id="username" name="username" className='w-100' /><br /> */}
                                </div>
                                <div className='Input_field'>
                                    <label htmlFor='email'>E-mail:</label>
                                    <br />
                                    <Field
                                        type="email"
                                        name="email"
                                        className="input"
                                        placeholder="example@gmail.com"
                                    />
                                    <ErrorMessage
                                        name="email"
                                        component="div"
                                        style={{ color: "red" }}
                                    />
                                    {/* <input type='email' id='email' name='email' className='w-100' /> */}
                                </div>
                                <div>
                                    <label htmlFor='password'>Password:</label>
                                    <br />
                                    <div className='Input_field'>
                                        <Field
                                            type={passwordVisible ? 'text' : 'password'}
                                            name="password"
                                            className="input"
                                            placeholder="********"
                                        />
                                        <ErrorMessage
                                            name="password"
                                            component="div"
                                            style={{ color: "red" }}
                                        />
                                        {/* <input
                                            type={passwordVisible ? 'text' : 'password'}
                                            id='password'
                                            name='password'
                                            className='Password_field'
                                        /> */}
                                        <span className='eye_icon' onClick={togglePasswordVisibility}>
                                            {passwordVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </span>
                                    </div>
                                </div>
                                
                                <button type='submit' className='signup_button'>
                                    Signup
                                </button>
                                <div className='create_acc'>
                                    <span>Already have an account?</span>  <Link to='/login' className='ms-1'>Sign in instead</Link>
                                </div>

                            </Form>
                        </Formik>
                    </div>


                </div>
            </div>
        </div>
    );
}

export default Registration;
