import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function BasicAccordion() {
    return (
        <div className='accordians'>
            <Accordion className='acrd_main'>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography className='acordn_heading'>How many videos can I download at a time?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography className='Content'>
                    You can put up to 100 links, but our suggestion is to add and download 10 links at a time. This will give you the best experience possible in terms of download speed and wait time.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion className='acrd_main'>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className='acordn_heading'>How long does it take to download more than 1 video at a time?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography className='Content'>
                    This depends on the speed of your internet, the amount of videos you’re downloading, and the size of the video files / length of video. Being that there’s a variety of variables at play, it usually takes up to 30secs for 10 videos, and 10secs for 10 cover images.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion className='acrd_main'>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                >
                    <Typography className='acordn_heading'>How do I open files when I mass download?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography className='Content'>
                    When you download multiple videos & Shorts videos at the same time, Ytube download will automatically download a Zip File with everything inside of it. ALl you simply need to do is “Extract” the files and or drag and drop them onto your desktop.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion className='acrd_main'>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                >
                    <Typography className='acordn_heading'>Where are Youtube videos saved after downloading?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography className='Content'>
                    When you save your Youtube videos & Shorts, the files are usually saved to the default location you selected. In your browser settings you can change the destination folder to anywhere you would like. Typically they are saved in the “Downloads” folder.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion className='acrd_main'>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                >
                    <Typography className='acordn_heading'>Do I need to have a Youtube account to download Youtube videos?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography className='Content'>
                    When you download multiple videos & Shorts videos at the same time, Ytube download will automatically download a Zip File with everything inside of it. ALl you simply need to do is “Extract” the files and or drag and drop them onto your desktop.
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}