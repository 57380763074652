import * as React from "react";
// import Box from '@mui/material/Box';
// import Tab from '@mui/material/Tab';
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box, Tab } from "@mui/material";
import VideoCards from "./VideoCards";
import Communitycards from "./Communitycards";
import ShortCards from "./ShortCards";
import Searchbar from "../Components/Searchbar";
import About from "../Components/About";
import { useDispatch, useSelector } from "react-redux";
import { youtubeTabsName } from "../store/slice/youtubeTabsSlice";
import { useEffect } from "react";
import { getYoutubeTabs, getYoutubeTabsAbout, getYoutubeTabsCommunity, getYoutubeTabsEvent, getYoutubeTabsHome, getYoutubeTabsShortsVideo, getYoutubeTabsStatus, getYoutubeTabsVideos } from "../store/slice/dataSlice";
import Playlist from "./Playlist";
import Hometabs from "./Hometabs";
import { toast } from "react-toastify";
// import Events from "./Events";
// import Status from "./Status";
import Topviewedbutton from "./Topviewedbutton";
import pageToken from './Playlist'

export default function LabTabs() {
  const [value, setValue] = React.useState("1");
  const dispatch = useDispatch()
  const youtube = useSelector(
    (state) => state?.searchYoutubeSlice?.searchData
    );


  const myHomeTabsData = useSelector(
    (state) => state?.searchYoutubeSlice
  );
  console.log('youtube', myHomeTabsData)
  const homeData = myHomeTabsData?.tabsHome?.data?.success
  const myVideoData = myHomeTabsData?.tabsVideo?.data?.success
  const myShortsVideoData = myHomeTabsData?.tabsShorts?.data?.success
  const myCommunity = myHomeTabsData?.tabsCommunity?.data?.success
  const myAbout = myHomeTabsData?.tabsAbout?.data?.success
  // const videoData = myHomeTabsData?.tabsVideo?.data?.data?.success
  // console.log('myCommunity', myCommunity )
  // console.log("myVideoData ",myVideoData?.success)

  //   console.log('youtube?.payload?.channelId', youtube?.channelId)
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //---code test------------------------
  // const [isLoading, setIsLoading] = React.useState(false);
  const [tabsName, setTabsName] = React.useState("");
  // console.log(":rocket: ~ file: Tabs.js:32 ~ LabTabs ~ tabsName:", tabsName)

  const [data, setData] = React.useState(null);

  // console.log(data);
  const fetchData = async () => {
    // setIsLoading(true);
    try {
      const res = await dispatch(youtubeTabsName({ tabsName }));
      // console.log("Hello this is response", res.payload);
      setData(res.payload);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      // setIsLoading(false);
    }
  };

  //----------------Home Tabs---------------
  const handleTabClickHome = async (value) => {
    setTabsName(value);
    // console.log("Clicked on Tab with value: Home Value", value);
    // console.log('homeeeeee data', data)
    const data = {
      portId: "playlists",
      channelId: youtube?.channelId
    }
    if (homeData !== true) {

      const response = await dispatch(getYoutubeTabsHome(data));
      handleTabClickVideos("playlists")
      // handleTabClickShortsVideos("shorts")
      // console.log(":rocket:  handleTabClick ~ response:", response)
    }
    else {
      // toast.success("Already loaded Data")
    }
  };
  //----------------Video Tabs---------------
  // console.log("Hello",youtube)
  useEffect(() => {
    console.log("This is hello")
    handleTabClickVideos("playlists")
    handleTabClickShortsVideos("shorts")
    handleTabClickHome("home")
    handleTabClickAbout("about")
    handleTabClickCommunity("community")
    handleTabClickEvent("upcomingEvents")
    handleTabClickStatus("status")
    handleTabClicklives("lives")
  }, [youtube.channelId])

  const handleTabClickVideos = async (value) => {
    setTabsName(value);
    // console.log("Clicked on Tab with value: Videos---", value);
    const data = {
      portId: value,
      channelId: youtube?.channelId,
    }
    // const response = await dispatch(getYoutubeTabsVideos(data));
    // console.log(":rocket:  handleTabClick ~ response Videos:", response)

    // if (myVideoData !== true) {
    const response = await dispatch(getYoutubeTabsVideos(data));
    // console.log(":rocket:  handleTabClick ~ response:", response)
    // }
    // else{
    // toast.success("Already loaded Data")
    // }
  };

  const handleTabClickShortsVideos = async (value) => {
    setTabsName(value);
    // console.log("Clicked on Tab with value: Videos---", value);
    const data = {
      portId: value,
      channelId: youtube?.channelId
    }

    // if (myShortsVideoData !== true) {

    const response = await dispatch(getYoutubeTabsShortsVideo(data));
    // console.log(":rocket:  handleTabClick ~ response Videos:", response)
    // }
    // else{
    // toast.success("Already loaded Data")
    // }
  };

  const handleTabClickCommunity = async (value) => {
    setTabsName(value);
    // console.log("Clicked on Tab with value: Videos---", value);
    const data = {
      portId: value,
      channelId: youtube?.channelId
    }

    // if (myCommunity !== true) {

    const response = await dispatch(getYoutubeTabsCommunity(data));
    // console.log(":rocket:  handleTabClick ~ response Videos:", response)
    // }
    // else{
    // toast.success("Already loaded Data")
    // }
  };

  const handleTabClickAbout = async (value) => {
    setTabsName(value);
    // console.log("Clicked on Tab with value: Videos---", value);
    const data = {
      portId: value,
      channelId: youtube?.channelId
    }
    const response = await dispatch(getYoutubeTabsAbout(data));
    // console.log(":rocket:  handleTabClick ~ response about:", response)
    // console.log('about data:',response)
  };

  const handleTabClick = async (value) => {
    setTabsName(value);
    // console.log("Clicked on Tab with value:", value);
    const data = {
      portId: value,
      channelId: youtube?.channelId
    }
    const response = await dispatch(getYoutubeTabs(data));
    // console.log(":rocket:  handleTabClick ~ response:", response)
    // fetchData();
  };

  const handleTabClickEvent = async (value) => {
    setTabsName(value);
    // console.log("Clicked on Tab with value:", value);
    const data = {
      portId: value,
      channelId: youtube?.channelId
    }
    const response = await dispatch(getYoutubeTabsEvent(data));
    // console.log(":Event data", response)

    // fetchData();
  };
  const handleTabClickStatus = async (value) => {
    setTabsName(value);
    // console.log("Clicked on Tab with value:", value);
    const data = {
      portId: value,
      channelId: youtube?.channelId
    }
    const response = await dispatch(getYoutubeTabsStatus(data));
    // console.log(":Status data", response)

    // fetchData();
  };
  const handleTabClicklives = async (value) => {
    setTabsName(value);
    // console.log("Clicked on Tab with value:", value);
    const data = {
      portId: value,
      channelId: youtube?.channelId
    }
    const response = await dispatch(getYoutubeTabsStatus(data));
    // console.log(":live data", response)

    // fetchData();
  };
  return (
    <div className="tabs_section">
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box
            sx={{ borderBottom: 1, borderColor: "divider" }}
            style={{ display: "flex" }}
          >
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab
                label="Home"
                value="1"
              // onClick={() => handleTabClickHome("home")}
              />
              <Tab
                label="Videos"
                value="2"
              // onClick={() => handleTabClickVideos("playlists")}
              />
              <Tab
                label="Shorts"
                value="3"
              // onClick={() => handleTabClickShortsVideos("shorts")}
              />
              <Tab
                label="Playlists"
                value="4"
              // onClick={() => handleTabClickVideos("playlists")}
              />
              <Tab
                label="Community"
                value="5"
              // onClick={() => handleTabClickCommunity("community")}
              />
              {/* <Tab
                label="Channels"
                value="6"
                onClick={() => handleTabClick("channels")}
              /> */}
              <Tab
                label="About"
                value="7"
              // onClick={() => handleTabClickAbout("about")}
              />
              {/* <Tab
                label="UpcomingEvents"
                value="8"
              // onClick={() => handleTabClickEvent("event")}
              /> */}
              {/* <Tab
                label="Status"
                value="9"
              // onClick={() => handleTabClickAbout("about")}
              /> */}
              {/* <Tab
                label="lives"
                value="10"
              // onClick={() => handleTabClickAbout("about")}
              /> */}
              {/* <p className="searching_tabs">
                <Searchbar />
              </p> */}
            </TabList>
          </Box>
          <TabPanel value="1">
            <div className="home_header">
              <div className="viewedbtn">
                {/* <Topviewedbutton /> */}
              </div>
              <Hometabs />
            </div>
          </TabPanel>
          <TabPanel value="2">
            <div className="home_header">
              <div className="viewedbtn">
                {/* <Topviewedbutton /> */}
              </div>
              <div className="home_heading">
                <h5>Videos</h5>
              </div>
              <VideoCards />
            </div>
          </TabPanel>
          <TabPanel value="3">
            <div className="home_header">
              <div className="viewedbtn">
                {/* <Topviewedbutton /> */}
              </div>
              <div className="home_heading">
                <h5>Shorts</h5>
              </div>
              <ShortCards />
            </div>
          </TabPanel>
          <TabPanel value="4">
            <div className="home_header">
              <div className="viewedbtn">
                {/* <Topviewedbutton /> */}
              </div>
              <div className="home_heading">
                <h5>Videos</h5>
              </div>
              <Playlist />
            </div>
          </TabPanel>
          <TabPanel value="5">
            <div className="community header">
              <div className="viewedbtn">
                {/* <Topviewedbutton /> */}
              </div>
            </div>
            <Communitycards />
          </TabPanel>
          <TabPanel value="6">
            <div className="channal_details">
              <span>This channel doesn't feature any other channels. </span>
            </div>
          </TabPanel>
          <TabPanel value="7">
            <div className="channal_details">
              <About />
            </div>
          </TabPanel>
          <TabPanel value="8">
            {/* <div className="channal_details">
              <div className="viewedbtn">
                <Topviewedbutton />
              </div>
              <Events />
            </div> */}
          </TabPanel>
          <TabPanel value="9">
            {/* <div className="channal_details">
              <div className="viewedbtn">
                <Topviewedbutton />
              </div>
              <Status />
            </div> */}
          </TabPanel>
          <TabPanel value="10">
            <div className="viewedbtn">
              {/* <Topviewedbutton /> */}
            </div>
            <div className="channal_details">
              hehehe
            </div>
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
}
