import './GlobalStyle.scss';
import './Responsive.scss';
import Main from "./Pages/Main";
import { Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Login from "./Pages/Login";
import Registration from "./Pages/Registration";
import ForgetPassword from './Pages/ForgetPassword'
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Play from './Components/Play';
// import { getData } from './service/api';

function App() {
  // const [isAuthenticated, setIsAuthenticated] = useState()
  // console.log("🚀 ~ file: App.js:16 ~ App ~ isAuthenticated:", isAuthenticated)

  // const auth = useSelector((state) => state?.auth)
  // console.log('auth', auth?.user)
  // console.log('isAuthenticated', auth?.isAuthenticated)

  // useEffect(() => {
  //   setIsAuthenticated(auth?.user?.isAuthenticated)
  // }, [auth])
  console.log('this is env', process.env.NODE_ENV)
  return (
    <div className="App">
      <ToastContainer />
      <Routes>
        {/* <Route path="/" element={isAuthenticated ? (<Navigate to="/main" />) : (<Login />)} /> */}
        <Route path="/" element={<Main />} />
        <Route path="/login" element={<Login />} /> 
        <Route path="/signup" element={<Registration />} />
        <Route path="/forgot-password" element={<ForgetPassword />} />
        <Route path="/play/:id" element={<Play />} />
        {/* <Route path="/main" element={isAuthenticated ? (<Main />) : (<Navigate to="/" />)} /> */}
      </Routes>




    </div>
  );
}

export default App;
