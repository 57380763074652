import React, { useEffect } from "react";
import { Card, Button, Dropdown, Col, Row } from "react-bootstrap";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useSelector } from "react-redux";
import { fetchShorts } from "../store/slice/dataSlice";

const ShortCards = () => {
  const shorts = useSelector(
    (state) => state?.searchYoutubeSlice?.tabsShorts?.data?.data[0].shorts || []
  );
  console.log("shorts:::::::", shorts);
  useEffect(() => {
    sendSortData();
  }, []);

  const loading = useSelector((state) => state?.searchYoutubeSlice);

  const sortDataHandler = () => {};

  const sendSortData = async () => {
    await fetchShorts(shorts);
  };

  if (loading?.loading === true) {
    return <h1>Loading ...</h1>;
  }

  return (
    <>
      <div className="viewedbtn">
        <Button className="btnaaa" variant="outlined" onClick={sortDataHandler}>
          Order By View Count
        </Button>
      </div>
      <div className="Shortcards">
        <div className="cards">
          <div className="AllCards">
            <Row>
              {shorts?.map((video, index) => (
                <Col lg="2" md="3" sm="4" key={index}>
                  <Card className="card_div">
                    <div className="card_details">
                      <div className="shortCard_img">
                        <a
                          href={`https://youtube.com/shorts/${video?.videoId}`}
                          target="_blank"
                        >
                          <img
                            alt="video-img"
                            src={
                              video?.thumbnails[0]?.url ||
                              "./Images/video-img.jpg"
                            }
                          ></img>
                          <Button className="download-btn">
                            <DownloadForOfflineIcon />
                          </Button>
                        </a>
                      </div>
                      <div className="card_intro">
                        <div>
                          <h5>
                            {video?.title ||
                              "Saathiya official videos Kumar sanu youngveer"}
                          </h5>
                          <div className="views_detail">
                            <span>
                              {`${video?.viewCount} views` || "123k views"}
                            </span>
                          </div>
                        </div>
                        <div>
                          <div className="Dropdown_box">
                            <Dropdown>
                              <Dropdown.Toggle
                                id="dropdown-button-dark-example1"
                                variant="secondary"
                              >
                                <MoreVertIcon />
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item href="">
                                  Send Feedback
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShortCards;
