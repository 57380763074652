import { toast } from "react-toastify";

export const detectError = (error, dispatch, rejectWithValue) => {
  if (rejectWithValue) {
    return rejectWithValue(error);
  }
};
// handle error leading and server params
function handleLoadingErrorParamsForAsyncThunk(state, { meta, payload, type }) {
  const action = type.split("/");
  if (meta?.arg && type.endsWith("/pending")) {
    state.paramsForThunk[action[1]] = meta?.arg;
  }

  if (type.endsWith("/rejected") && payload?.response) {
    state.errorMessages[action[1]] =
      payload?.response?.data?.message ??
      payload?.response?.message ??
      "Something went wrong";
    state.errorCodes[action[1]] = payload?.response?.status ?? 500;
    toast.error(state.errorMessages[action[1]]);
  }
  state.errors[action[1]] = type.endsWith("/rejected");
  state.loadings[action[1]] = type.endsWith("/pending");
}
export { handleLoadingErrorParamsForAsyncThunk };

export const catchAsync = (fn) => (_, api) => {
  return Promise.resolve(fn(_, api)).catch((error) => {
    return detectError(error, api?.dispatch, api?.rejectWithValue);
  });
};
export const reduxToolKitCaseBuilder = (cases) => {
  return cases.flatMap((el) => [el.pending, el.fulfilled, el.rejected]);
};
