import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { ApiRequests } from "../../service/ApiRequests";
import {
  catchAsync,
  handleLoadingErrorParamsForAsyncThunk,
  reduxToolKitCaseBuilder,
} from "../../helper/detectError.ts";

export const getYoutubeShortsThunk = createAsyncThunk(
  "youtubeSlice/getYoutubeShortsThunk",
  catchAsync(async ({username}) => {
    console.log(username)
    const res = await ApiRequests.getData({username});
    return res?.data;
  })
);

const initialState = {
  //news states
  info:{},
  // manager states
  errors: {},
  loadings: {},
  errorMessages: {},
  errorCodes: {},
  paramsForThunk: {},
};

const blogSlice = createSlice({
  name: "youtubeSlice",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getYoutubeShortsThunk.fulfilled, (state, action) => {
        state.info = action.payload;
      })
      .addMatcher(
        isAnyOf(...reduxToolKitCaseBuilder([getYoutubeShortsThunk])),
        handleLoadingErrorParamsForAsyncThunk
      );
  },
});

export default blogSlice.reducer;
export const { setLoading, setType, setUser, setTokens } = blogSlice.actions;
