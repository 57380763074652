export const version = "v1";
export const baseURL = process.env.NODE_ENV === "development" ? 'http://localhost:8000/api/v1/' : 'https://yttapi.tokbackup.com/api/v1/'
export const BASEURL = process.env.NODE_ENV === "development" ? 'http://localhost:8000/v1/' : 'https://yttapi.tokbackup.com/v1/'
export const APIurls = {
  // auth endpoints
  login: "auth/login",
  logout: "auth/logout",
  register: "auth/register",
  authenticate: "auth/authenticate",
  refreshTokens: "auth/refresh-tokens",
  forget: "auth/forgot-password",
  resetPassword: "auth/reset-password",
  // dashboard endpoints
  counts: "analytics/counts",
  // 
  users: "users",
  adminChats: "chats/admin",
  chats: "chats",
};
