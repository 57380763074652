import { useState } from "react";
// import Model from './components/Model';
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import LabTabs from "./Tabs";
import { useSelector } from "react-redux";


function Home({ data }) {
  console.log("Home data----", data);
  // const [model, setModel] = useState(false);
  // const handleOpen = () => setModel(true);
  // const handleClose = () => setModel(false);

  const searchData = useSelector((state) => state?.searchYoutubeSlice?.searchData);

  const { youtube } = searchData || {};
  console.log('youtube:::::::::', youtube)


  return (
    <div className="containers">
      {/* <Topbar/> */}
      <div className="banner-div">

        <img
          src={searchData?.data?.header?.c4TabbedHeaderRenderer?.banner?.thumbnails[0]?.url || "/Images/download.jpg"}
          alt="banner-img"
          className="banner-img"
        ></img>

      </div>
      <div className="yt-container">
        <div className="img-div">
          <img
            src={data?.avatar || "/Images/download.jpg"}
            alt="img"
            className="yt-image"
          />
        </div>
        <div className="box-content">
          <h1 className="yt-title">{data?.name || "Genius Mind Zone"}</h1>
          <p>
            {" "}
            @{data?.username || "geniusmindzone"}
            . {data?.subscribers || " . 17 subscribers. 23 "}
            . {data?.videosCountText?.[0]?.text} videos
          </p>
          <div className="yt-description">
            <p className="d-flex">
              {" "}
              <span className="desc">
                {data?.description || "Welcome to genius mind zone channel"}{" "}
              </span>
              <KeyboardArrowRightIcon sx={{ fontSize: 25 }} />
            </p>
          </div>
          <p>
            <a href={data?.url} style={{ color: "#085fb5", textDecoration: "none" }} target="_blank">
              {data?.username ||"geniusmindzone.com"} 
            </a> {" "} 
            <span style={{ color: "black" }}>
              and 3 more links
            </span>{" "}
          </p>
          {/* <button className='btn'>Subscribe</button> */}
        </div>
      </div>
      {
        // model && <Model open={model} setOpen={setModel}/>
      }
      <div className="tabs_section">
       
        <div className="tabs">
          <LabTabs />
        </div>
      </div>
    </div>
  );
}

export default Home;
