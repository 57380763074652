import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
// import Dropdown from 'react-bootstrap/Dropdown';
import Button from "react-bootstrap/Button";
// import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import { useSelector } from "react-redux";
// import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Link } from "react-router-dom";

export default function Playlist() {



  const playlist = useSelector(
    (state) => state?.searchYoutubeSlice?.tabsVideo?.data?.data?.[0]?.playlists
  );



  if (playlist?.loading === true) {
    return <img src={"../Images/Animation.gif"} alt="" />;
  }
  return (
    <div className="Playlistcard">
      <div className="cards">
        <div className="AllCards">
          <Row>
            {playlist?.map((obj, i) => {
              // console.log("obj", obj);
              return (
                <Col lg="3" md="3" sm="4" key={i}>
                  <Card className="card_div">
                    <div className="card_details">
                      <div className="Card_img">
                        <video
                          poster={obj?.thumbnailVideo?.thumbnails[0]?.url}
                          controls
                        >
                          <source
                            src={`https://www.youtube.com/embed/${obj?.id}`}
                            type="video/mp4"
                          />
                          Your browser does not support the video tag.
                        </video>
                        <Button className="download-btn">
                          {obj?.videoCount || "8 videos"} videos
                        </Button>
                        <Link to={`/play/${obj?.id}`} className="play_button">
                          Play All
                        </Link>
                      </div>
                      <div className="card_intro">
                        <div>
                          <h5>{obj?.title}</h5>
                          {obj?.publishedTimeText}
                          <div className="views_detail">
                            <span>View full playlist</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
      <button className="show_more">Show more</button>
    </div>
  );
}
