import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { ApiRequests } from "../../service/ApiRequests";
import {
  catchAsync,
  handleLoadingErrorParamsForAsyncThunk,
  reduxToolKitCaseBuilder,
} from "../../helper/detectError.ts";

export const getChatsAsyncThunk = createAsyncThunk(
  "chat/getChatsAsyncThunk",
  catchAsync(async ({ params }, { rejectWithValue }) => {
    console.log("params", params);
    const res = await ApiRequests.getChats(params);
    return res?.data;
  })
);
export const getChatAsyncThunk = createAsyncThunk(
  "chat/getChatAsyncThunk",
  catchAsync(async ({ id }, { rejectWithValue }) => {
    console.log("id", id);
    const res = await ApiRequests.getChat(id);
    return res?.data;
  })
);

const initialState = {
  //news states
  chats: {
    page: 1,
    limit: 10,
    totalPages: 1,
    totalResults: 0,
    results: [],
  },
  chat: {
    messages: [],
  },
  // manager states
  errors: {},
  loadings: {},
  errorMessages: {},
  errorCodes: {},
  paramsForThunk: {},
};

const blogSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setType(state, action) {
      state.type = action.payload;
    },
    setChat(state, action) {
      state.chat = action.payload;
    },
    setTokens(state, action) {
      state.tokens = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getChatsAsyncThunk.fulfilled, (state, action) => {
        state.chats = action.payload;
      })
      .addCase(getChatAsyncThunk.fulfilled, (state, action) => {
        state.chat = action.payload;
      })
      .addMatcher(
        isAnyOf(
          ...reduxToolKitCaseBuilder([getChatsAsyncThunk, getChatAsyncThunk])
        ),
        handleLoadingErrorParamsForAsyncThunk
      );
  },
});

export default blogSlice.reducer;
export const { setLoading, setType, setChat, setTokens } = blogSlice.actions;
