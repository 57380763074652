import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import { useSelector } from "react-redux";
import MoreVertIcon from "@mui/icons-material/MoreVert";

export default function VideoCards() {
  const playlist = useSelector(
    (state) => state?.searchYoutubeSlice?.tabsVideo?.data?.data?.[0]?.playlists
  );

  console.log("playlist:::::::::::", playlist);
  // const data = playlist?.tabsVideo?.data?.data?.playlists;

  if (playlist?.loading === true) {
    return <img src={"../Images/Animation.gif"} alt="" />;
  }
  // console.log("data:::::::::::",data)

  return (
    <div className="Videocards">
      <div className="cards">
        <div className="AllCards">
          <Row>
            {/* <img src='/Images/Animation.gif' className='loader-img'/> */}
            {playlist?.map((obj, i) => {
              // console.log("obj", obj);
              return (
                <Col lg="3" md="4" sm="6" key={i}>
                  {obj?.firstVideos?.map((item, index) => {
                    return (
                      <Card className="card_div" key={index}>
                        <div className="card_details">
                          <div className="Card_img">
                            {/* <video
                              poster={item?.thumbnails[0]?.url}
                              // width="100%"
                              // height="100%"
                              controls
                            >
                              <source
                                src={`https://www.youtube.com/embed/${item?.id}`}
                                type="video/mp4"
                              />
                              Your browser does not support the video tag.
                            </video> */}
                            <a
                              href={`https://youtube.com/shorts/${item?.id}`}
                              target="_blank"
                            >
                              <img
                                alt="video-img"
                                src={
                                  item?.thumbnails[0]?.url ||
                                  "./Images/video-img.jpg"
                                }
                              ></img>
                              <Button className="download-btn">
                                <DownloadForOfflineIcon />
                              </Button>
                            </a>
                            {/* <Button className="download-btn">
                              <DownloadForOfflineIcon />
                            </Button> */}
                          </div>
                          <div className="card_intro">
                            <div>
                              <h5>{obj?.title}</h5>
                              <div className="views_detail">
                                <span>{obj?.viewCount}Views</span>
                                <span>
                                  . {obj?.uploadDate || "Uploaded recently"}{" "}
                                </span>
                              </div>
                            </div>
                            <div className="Dropdown_box">
                              <Dropdown>
                                <Dropdown.Toggle
                                  id="dropdown-button-dark-example1"
                                  variant="secondary"
                                >
                                  <MoreVertIcon />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item href="">
                                    Add to queue
                                  </Dropdown.Item>
                                  <Dropdown.Item href="#/action-2">
                                    Save to Watch Later
                                  </Dropdown.Item>
                                  <Dropdown.Item href="#/action-3">
                                    Save to playlist
                                  </Dropdown.Item>
                                  <Dropdown.Item href="#/action-4">
                                    Share
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        </div>
                      </Card>
                    );
                  })}
                </Col>
              );
            })}
          </Row>
          <button>Show More</button>
        </div>
      </div>
    </div>
  );
}
