import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
// import MenuIcon from '@mui/icons-material/Menu';
// import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from "@mui/icons-material/AccountCircle";
// import MailIcon from '@mui/icons-material/Mail';
// import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from "@mui/icons-material/MoreVert";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { logoutAsyncThunk } from "../store/slice/authSlice";
import { RESET_DATA, logoutAsyncFun, resetAsyncThunk } from "../store/slice/dataSlice";
// import Dark_lightMode from '../Components/Dark_lightMode';
// import Dark from '../Components/Dark';

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

export default function PrimarySearchAppBar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const resetData = async () =>{
    // alert('Ia m cai')
    dispatch(resetAsyncThunk()) 
    
    window.location.reload()

  }


  const auth = useSelector((state) => state?.auth);
  const myAuth = auth?.user?.isAuthenticated;
 
  const logoutHandler = async (event) => {
    if (myAuth) {
      localStorage.removeItem("app-access-token");
      localStorage.removeItem("app-refresh-token");
      localStorage.removeItem("user");
      localStorage.removeItem("isAuthenticated");
      localStorage.removeItem("searchData");
      toast.success("Logged Successfully!", {
        autoClose: 2000,
      });

      const response = await dispatch(logoutAsyncThunk());
      console.log("🚀 ~ logout~ response:", response);
      dispatch(logoutAsyncFun());
      navigate("/");
    } else {
      toast.success("Logged Already !", {
        autoClose: 2000,
      });
    }
  };

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {myAuth ? (
        <div>
          <MenuItem onClick={handleMenuClose}>
            <Button className="Logout-btn">Profile</Button>{" "}
          </MenuItem>
          <MenuItem onClick={handleMenuClose}>
            <Button className="Logout-btn"> My account</Button>
          </MenuItem>
          <MenuItem onClick={handleMenuClose}>
            <Link to="/login">
              <Button
                variant="contained"
                onClick={logoutHandler}
                sx={{
                  textTransform: "none",
                }}
                className="Logout-btn"
              >
                LOGOUT
              </Button>
            </Link>
          </MenuItem>{" "}
        </div>
      ) : (
        <MenuItem onClick={handleMenuClose}>
          <Link to="/login">
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
              }}
              className="Logout-btn"
            >
              LOGIN
            </Button>
          </Link>
        </MenuItem>
      )}
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {myAuth ? (
        <div>
          <MenuItem onClick={handleMenuClose}>
            <Button className="Logout-btn">Profile</Button>{" "}
          </MenuItem>
          <MenuItem onClick={handleMenuClose}>
            <Button className="Logout-btn"> My account</Button>
          </MenuItem>
          <MenuItem onClick={handleMenuClose}>
            <Button
              variant="contained"
              onClick={logoutHandler}
              sx={{
                textTransform: "none",
              }}
              className="Logout-btn"
            >
              LOGOUT
            </Button>
          </MenuItem>
        </div>
      ) : (
        <MenuItem onClick={handleMenuClose}>
          <Link to="/login">
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
              }}
              className="Logout-btn"
            >
              LOGIN
            </Button>
          </Link>
        </MenuItem>
      )}
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }} className="topbar_main">
      <AppBar position="static">
        <Toolbar>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { xs: "flex", sm: "flex" } }}
          >
            
             <a href="" onClick={resetData}>
             <img src={"/Images/youtube-logo.png"} className="nav_logo"></img>
             </a>
          
            {/* <p style={{ display: 'flex', alignItems: 'center' }} >Ytube download</p> */}
          </Typography>
          {/* <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
            />
          </Search> */}
          <Box sx={{ flexGrow: 2 }} />
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            {/* <IconButton
              style={{ display: "flex" }}
              size="large"
              color="inherit"
            >
              <Badge></Badge>
            </IconButton> */}
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="#0f0f0f"
            >
              <AccountCircle />
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="#0f0f0f"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </Box>
  );
}
