import { APIurls, BASEURL, baseURL } from "../constants";
import apiService from ".";

export const ApiRequests = {
  //user Authentication
  login: async (data) =>
    await apiService.post(BASEURL + APIurls.login, data, {
      withCredentials: true,
    }),
  logout: async (data) => await apiService.post(APIurls.logout, data, {
    withCredentials: true,
  }),
  register: async (data) => await apiService.post(BASEURL + APIurls.register, data, {
    withCredentials: true,
  }),
  authenticate: async () =>
    await apiService.get(baseURL + APIurls.authenticate),
  resetPassword: async () => await apiService.get(APIurls.resetPassword),
  forgotPassword: async () => await apiService.get(APIurls.forgotPassword),
  refreshTokens: async (data) =>
    await apiService.post(APIurls.refreshTokens, data),
  getCounts: async () => await apiService.get(APIurls.counts),
  // users
  creteUser: async (data) => apiService.post(APIurls.users, data),
  getUser: async (id) => apiService.get(APIurls.users + "/" + id),
  getUsers: async (params) => apiService.get(APIurls.users+'/with-related-counts', { params }),
  updateUser: async (id, data) =>
    apiService.patch(APIurls.users + "/" + id, data),
  deleteUser: async (id) => apiService.delete(APIurls.users + "/" + id),
  // users
  // creteChats: async (data) => apiService.post(APIurls.chats, data),
  // getChat: async (id) => apiService.get(APIurls.chats + "/" + id),
  // getChats: async (params) => apiService.get(APIurls.adminChats, { params }),
  // updateChats: async (id, data) =>
  //   apiService.patch(APIurls.chats + "/" + id, data),
  // deleteChats: async (data) => apiService.delete(APIurls.chats),
  getData:async ({username})=>apiService.get(`/basicdata?username=${username}`)
};
