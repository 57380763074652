import React, { useState } from "react";
import * as yup from "yup";
// import { auth, db } from "../firebase-config";
// import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
// import LoggedInOrNot from "../utilities/LoggedInOrNot";
import { useDispatch } from "react-redux";
import { userForgetAsyncThunk } from "../store/slice/authSlice";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { toast } from "react-toastify";

function ForgotPassword({ loginModalRef }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");

  const [errorMessage, setErrorMessage] = useState("");

  let schema = yup.object().shape({
    email: yup.string().email().required("Email is required"),
  });

  const [loginLoading, setLoginLoading] = useState(false);

  const handleForgotPassword = async (
    values,
    { setSubmitting, setFieldError }
  ) => {
    try {
      await schema.validate(values);
      const { payload } = await dispatch(userForgetAsyncThunk(values));
      const errorMessage =
        payload?.response?.data?.code !== 201
          ? payload?.response?.data?.message
          : null;
      if (errorMessage) {
        toast.error(errorMessage);
      }
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        for (const fieldError of err.errors) {
          setFieldError(fieldError.split(":")[0], fieldError);
        }
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div>
      {/* <div className="Login_page">
        <div className="block p-6 rounded-lg shadow-lg bg-white max-w-sm m-auto w-[90%] mt-[75px]">
          <div>
            <h1 className="text-2xl font-semibold mb-6">
              Forgot Password
            </h1>

            <Formik
              initialValues={{ email: "" }}
              validationSchema={schema}
              onSubmit={handleForgotPassword}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="form-group mb-6">
                    <label
                      htmlFor="exampleInputEmail2"
                      className="form-label inline-block mb-"
                      style={{marginBottom:"10px", marginLeft: "10px" , display:"flex" }}
                    >
                      Email address
                    </label>
                    <Field
                      type="email"
                      name="email"
                      className="form-control block w-full px-3 py-1.5 text-base font- bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      id="exampleInputEmail2"
                      aria-describedby="emailHelp"
                      placeholder="Enter email"
                    />
                    <ErrorMessage
                      name="email"
                      component="p"
                      className="text-red-500 text-xs text-left mt-1"
                      style={{ marginLeft: 10 }}
                    />
                  </div>
                  <button
                    type="submit"
                    style={{ opacity: isSubmitting ? "0.5" : "1" }}
                    className="w-full px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Submitting..." : "Submit"}
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div> */}
      <div className='Password_Page'>
        <div className='card'>
          <div className='logo'>
            <img src='/Images/favicon.ico' className='logo_image' alt='Logo' />
          </div>
          <div className='Logo_content'>
            <h5>Welcome to Ytube!</h5>
          </div>
          <div>
            <Formik
              initialValues={{ email: "" }}
              validationSchema={schema}
              onSubmit={handleForgotPassword}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="form-group">
                    <label
                      htmlFor="exampleInputEmail2"
                      className="w-100"
                      // style={{ marginBottom: "10px", marginLeft: "10px", display: "flex" }}
                    >
                      Email address
                    </label>
                    <Field
                      type="email"
                      name="email"
                      className="input"
                      id="exampleInputEmail2"
                      aria-describedby="emailHelp"
                      placeholder="Enter email"
                    />
                    <ErrorMessage
                      name="email"
                      component="p"
                      className="Error_msg"
                      // style={{ marginLeft: 10 }}
                    />
                  </div>
                  <button
                    type="submit"
                    style={{ opacity: isSubmitting ? "0.5" : "1" }}
                    className="Login_button"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Submitting..." : "Submit"}
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
