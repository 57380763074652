import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { ApiRequests } from "../../service/ApiRequests";
import {
  catchAsync,
  handleLoadingErrorParamsForAsyncThunk,
  reduxToolKitCaseBuilder,
} from "../../helper/detectError.ts";
import { toast } from "react-toastify";

export const loginAsyncThunk = createAsyncThunk(
  "auth/userLoginAsyncThunk",
  catchAsync(async ({ email, password, cb }, _) => {
    const response = await ApiRequests.login({ email, password });
    if (response) {
      if (response?.status == 200) {
        toast.success("LoggedIn Successfully!", {
          autoClose: 2000,
        });
        console.log('response', response?.data)
        if (cb) cb();
      } else {
        toast.error(response.error);
      }
    }
    return response?.data;
  })
);

export const registerAsyncThunk = createAsyncThunk(
  "auth/userRegisterAsyncThunk",
  catchAsync(
    async ({
      userName,
      email,
      password,
      cb,
    }) => {
      const response = await ApiRequests.register({
        userName,
        email,
        password,
      });
      if (response) {
        if (response?.status == 201) {
          toast.success("Registered Successfully!!!", {
            autoClose: 2000,
          });

          if (cb) cb();
        } else {
          toast.error(response.error);
        }
      }
      return response?.data;
    }
  )
);

export const userForgetAsyncThunk = createAsyncThunk(
  "auth/userForgetAsyncThunk",
  catchAsync(async (email, { rejectWithValue }) => {
    const res = await ApiRequests.forgotPassword(email);
    return res?.data;
  })
);

export const resetPasswordAsyncThunk = createAsyncThunk(
  "auth/resetPasswordAsyncThunk",
  catchAsync(async (resetData, { rejectWithValue }) => {
    const res = await ApiRequests.resetPassword(resetData);
    return res?.data;
  })
);

export const authenticateAsyncThunk = createAsyncThunk(
  "auth/authenticateAsyncThunk",
  catchAsync(async ({ navigate }, { rejectWithValue }) => {
    try {
      const res = await ApiRequests.authenticate();
      return res?.data;
    } catch (error) {
      navigate("/login");
      rejectWithValue(error);
    }
  })
);

export const logoutAsyncThunk = createAsyncThunk(
  "auth/logoutAsyncThunk",
  catchAsync(async ({ cb }, { rejectWithValue }) => {
    // Assuming you have an API request for logging out
    const res = await ApiRequests.logout();
    
    if (cb && typeof cb === "function") cb();
    return res?.data;
  })
);


const user = () => {
  try {
  let user = localStorage.getItem("user");
  if (user) user = JSON.parse(user);
  return user ?? {};
  } catch (error) {
  return {};
  }
  };
const initialState = {
  //news states
  user: user(),
  // user: {},
  isAuthenticated: false,
  // manager states
  errors: {},
  loadings: {},
  errorMessages: {},
  errorCodes: {},
  paramsForThunk: {},
};

const blogSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setType(state, action) {
      state.type = action.payload;
    },
    setUser(state, action) {
      state.user = action.payload;
    },
    setTokens(state, action) {
      state.tokens = action.payload;
    },

    
  },
  extraReducers: (builder) => {

    builder
      .addCase(authenticateAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.user) {
          state.user = action.payload?.user;
          state.isAuthenticated = true;
        } else {
          state.user = {};
          state.isAuthenticated = false;
        }
      })
      .addCase(authenticateAsyncThunk.rejected, (state, action) => {
        state.user = {};
        state.isAuthenticated = false;
      })
      .addCase(loginAsyncThunk.fulfilled, (state, action) => {
        // state.isAuthenticated = true;
        // state.user = action.payload?.user;
        // localStorage.setItem(
        //   "app-access-token",
        //   JSON.stringify(action.payload?.tokens?.access)
        //   );
        //   localStorage.setItem(
        //   "app-refresh-token",
        //   JSON.stringify(action.payload?.tokens?.refresh)
        //   );
        //   localStorage.setItem("user", JSON.stringify(action.payload?.user));
        //   localStorage.setItem("isAuthenticated", JSON.stringify(state.isAuthenticated));

        state.user = {
          ...action.payload?.user,
          isAuthenticated: true,
        };
        
        
        localStorage.setItem("app-access-token", JSON.stringify(action.payload?.tokens?.access));
        localStorage.setItem("app-refresh-token", JSON.stringify(action.payload?.tokens?.refresh));
        localStorage.setItem("user", JSON.stringify(state.user));
          

      })
      .addCase(logoutAsyncThunk.fulfilled, (state, action) => {
        state.user = {};
        state.isAuthenticated = false;
      })
      .addCase(logoutAsyncThunk.rejected, (state, action) => {
        state.user = {};
        state.isAuthenticated = false;
      })
      .addMatcher(
        isAnyOf(
          ...reduxToolKitCaseBuilder([
            authenticateAsyncThunk,
            loginAsyncThunk,
            registerAsyncThunk,
            resetPasswordAsyncThunk,
            userForgetAsyncThunk,
            logoutAsyncThunk,
          ])
        ),
        handleLoadingErrorParamsForAsyncThunk
      );
  },
});

export default blogSlice.reducer;
export const { setLoading, setType, setUser, setTokens } = blogSlice.actions;
