import { combineReducers } from "@reduxjs/toolkit";
import auth from "./slice/authSlice";
import dashboard from "./slice/dashboardSlice";
import users from "./slice/userSlice";
import chats from "./slice/chatSlice";
import youtubeSlice from "./slice/youtubeSlice";
import searchYoutubeSlice from "./slice/dataSlice"

const rootReducer = combineReducers({
  auth,
  dashboard,
  users,
  chats,
  youtubeSlice,
  searchYoutubeSlice
  // youTubsNames,
});

export default rootReducer;
