import React from 'react';
import { Facebook, GraphicEq, HomeWorkOutlined, InfoRounded, Instagram, LinkedIn, Mail, Person2, VideoFileSharp } from '@mui/icons-material';
import Button from 'react-bootstrap/Button';
import { useSelector } from 'react-redux';


const About = () => {
    const about = useSelector(
        (state) => state?.searchYoutubeSlice?.tabsAbout?.data?.data?.[0]?.about
      );
      console.log("🚀  ~ about_part:", about)
    //   const loading = useSelector((state) => state?.searchYoutubeSlice)
    //   console.log("🚀 ~ loading:", loading)
    //   if (loading?.loading === true) {
    //     return <h1>Loading ...</h1>
    //   }

    // const playlist = useSelector(
    //     (state) =>
    //         state?.searchYoutubeSlice
    // );
    //   console.log('asdgshjadgjshadg' , playlist)

    // const about = playlist?.tabsAbout?.data?.data?.items?.[0]?.about;
    // console.log('__about', about)
    // if (playlist?.loading === true) {
    //     return <img src={"../Images/Animation.gif"} alt="" />;
    // }


    return (
        <div className='About_tag'>
            <div className='about_part'>
                <h4>About</h4>
                <p>{about?.description || 'Welcome to genius mind zone channel'}</p>
            </div>
            <div className='Links_part'>
                <h5>Links</h5>
                <div className='flex_box'>
                    <img src={about?.links[0]?.favicon[0]?.url}></img>
                    {/* <Facebook className='fb' /> */}
                    <div className='dtl'>
                        <h5>{about?.links[0]?.title || 'Facebook.com'}</h5>
                        <a href={about?.links[0]?.url} style={{ textDecoration: "none" }} target='_blank'>{about?.links[0]?.url || 'Facebook.com/abc'}</a>
                    </div>
                </div>
                <div className='flex_box'>
                    <img src={about?.links[1]?.favicon[0]?.url}></img>
                    <div className='dtl'>
                        <h5>{about?.links[1]?.title || 'Facebook.com'}</h5>
                        <a href={about?.links[1]?.url} style={{ textDecoration: "none" }} target='_blank'>{about?.links[1]?.url || 'Facebook.com/abc'}</a>
                    </div>
                </div>
                <div className='flex_box'>
                    <img src={about?.links[2]?.favicon[0]?.url}></img>
                    <div className='dtl'>
                        <h5>{about?.links[2]?.title || 'Facebook.com'}</h5>
                        <a href={about?.links[2]?.url} style={{ textDecoration: "none" }} target='_blank'>{about?.links[2]?.url || 'Facebook.com/abc'}</a>
                    </div>
                </div>
            </div>
            <div className='channel_section'>
                <h5>Channel detail</h5>
                <div className='mail'>
                    <Mail className='e-mail' />
                    <p>View Email adddress</p>
                </div>
                <div className='mail'>
                    <HomeWorkOutlined className='home' />
                    <p>www.youtube.com</p>
                </div>
                <div className='mail'>
                    <Person2 className='person' />
                    <p>subscribers {about?.stats?.subscriberCount || '444k subscribers'}</p>
                </div>
                <div className='mail'>
                    <VideoFileSharp className='video_file' />
                    <p>Videos {about?.stats?.videoCount || '202 Videos'}</p>
                </div>
                <div className='mail'>
                    <GraphicEq className='' />
                    <p>Views {about?.stats?.viewCount || '20212233 Views'}</p>
                </div>
                <div className='mail'>
                    <InfoRounded />
                    <p>Joined since {about?.stats?.joinedDate} </p>
                </div>
            </div>

            <div className='Btn_section'>
                <div>
                    <Button>Share Channel</Button>
                </div>
                <div>
                    <Button className='ms-2'>Report User</Button>
                </div>
            </div>

        </div>
    )
}

export default About
