import React, { useState } from "react";
import Topbar from "../Pages/Topbar";

import { Card, Col, Container, Row } from "react-bootstrap";
import Accordians from "../Components/Accordians";

import Footer from "./Footer";
import SearchSection from "./SearchSection";
import Home from "./Home";
import { useSelector } from "react-redux";
export default function Main() {
  const [show, setShow] = useState(false);


  const searchData = useSelector((state) => state?.searchYoutubeSlice?.searchData);

  const { youtube } = searchData || {};
  // console.log("🚀 ~ main ~ youtube:", searchData);

  // console.log('show', show)
  return (
    <div className="mainpage">
      <Topbar />
      <div className="main_content">
        <SearchSection show={show} setShow={setShow} />
        {youtube?.id ? (
          <Home data={youtube} />
        ) : (
          <div className="info_section">
            <Card className="Info_card">
              <Container>
                <Row className="container_row">
                  <Col lg="4" md="6" className="comp_card">
                    <img
                      src={"/Images/download.png"}
                      alt="img"
                      className="yt-image"
                    />
                    <div className="card_content">
                      <h5>Multi Download</h5>
                      <p>Save time by downloading multiple videos at once.</p>
                    </div>
                  </Col>
                  <Col lg="4" md="6" className="comp_card">
                    <img
                      src={"/Images/download2.png"}
                      alt="img"
                      className="yt-image"
                    />
                    <div className="card_content">
                      <h5>Unlimited Downloads</h5>
                      <p>Download unlimited youtube videos </p>
                    </div>
                  </Col>
                  <Col lg="4" md="6" className="comp_card">
                    <img
                      src={"/Images/abc.jpg"}
                      alt="img"
                      className="yt-image"
                    />
                    <div className="card_content">
                      <h5>Download Shorts</h5>
                      <p>Download the Youtube Shorts with 1 click!</p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Card>
            <Card className="text_card">
              <div className="text_container">
                <h5>How to download Youtube Videos & Shorts Videos ?</h5>
                <p>
                  Ytube download is a free tool helping you download Youtube
                  Videos and Shorts videos . You can even download more than 1
                  video at time by pasting your links at one time. Ytube
                  download will automatically download them all for you with 1
                  click of a button! Save Youtube Videos with the best quality
                  in HD resolution. Just copy and paste your Youtube Channel
                  links up above and hit download! It’s that simple.
                </p>
              </div>
            </Card>
            <Card className="text_card">
              <div className="text_container">
                <h5>FREQUENTLY ASKED QUESTIONS</h5>
                <Accordians />
              </div>
            </Card>
          </div>
        )}

        <div className="footer">
          <Footer />
        </div>
      </div>
    </div>
  );
}
