import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { loginAsyncThunk } from "../store/slice/authSlice";
import { ErrorMessage, Field, Form, Formik } from 'formik';



const Login = () => {

    const [passwordVisible, setPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const router = useNavigate();
    const initialValues = {
        email: "",
        password: "",
    };
    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email("Invalid email address")
            .required("Email is required"),
        password: Yup.string().required("Password is required"),
    });
    const handleSubmit = (values) => {
        dispatch(
            loginAsyncThunk({
                email: values.email,
                password: values.password,
                cb: () => {
                    router("/");
                },
            })
        );
        console.log(values);
    };

    return (
        <div>
            <div className='Login_page'>
                <div className='card'>
                    <div className='logo'>
                        <img src='/Images/favicon.ico' className='login_image' alt='Logo' />
                    </div>
                    <div className='Login_content'>
                        <h5>Welcome to Ytube!</h5>
                        <p>Please sign-in to your account and start the adventure</p>
                    </div>
                    <div>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            <Form >
                                <div className='Input_field'>
                                    <label htmlFor='email'>E-mail:</label>

                                    <Field
                                        type="email"
                                        name="email"
                                        className="input"
                                        placeholder="example@gmail.com"
                                    />
                                    <ErrorMessage
                                        name="email"
                                        component="div"
                                        style={{ color: "red" }}
                                    />
                                    {/* <input type='email' id='email' name='email' className='w-100' /> */}
                                </div>
                                <div>
                                    <label htmlFor='password'>Password:</label>

                                    <div className='Input_field'>
                                        <Field
                                            type={passwordVisible ? 'text' : 'password'}
                                            name="password"
                                            className="input"
                                            placeholder="********"
                                        />
                                        <ErrorMessage
                                            name="password"
                                            component="div"
                                            style={{ color: "red" }}
                                        />
                                        {/* <input
                                            type={passwordVisible ? 'text' : 'password'}
                                            id='password'
                                            name='password'
                                            className='Password_field'
                                        /> */}
                                        <span className='eye_icon' onClick={togglePasswordVisibility}>
                                            {passwordVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </span>
                                    </div>
                                </div>
                                <div className='forget'>
                                    <a
                                        href=""
                                        onClick={() => navigate("/forgot-password")}
                                        className="text-blue-600 hover:text-blue-700 focus:text-blue-700 transition duration-200 ease-in-out"
                                    >
                                        Forgot password?
                                    </a>
                                </div>
                                <div>
                                    <button type='submit' className='Login_button'>
                                        Login
                                    </button>
                                </div>
                            </Form>
                        </Formik>
                    </div>
                    <div className='create_acc'>
                        <span>New on our Platform?</span>{' '}
                        <Link to='/signup' className='ms-1'>
                            Create an account
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
