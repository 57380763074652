import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { baseURL } from "../../constants";

//_____________________________ Search Youtube Chanel ___________________________________

export const searchYoutubeChanel = createAsyncThunk(
  "post/searchYoutubeChanel",
  async (data) => {
    try {
      const response = await axios.post(
        `${baseURL}channel`,
        data
      );
      // console.log("baseurl", baseURL);
      return response?.data;
    } catch (error) {
      throw error?.response?.data;
    }
  }
);

//_____________________________Save user ip_______________________________

const getPublicIP = async () => {
  try {
    const response = await fetch('https://api.ipify.org?format=json');
    const data = await response.json();
    return data.ip;
  } catch (error) {
    console.error('Error fetching public IP:', error);
    return null;
  }
};

const userPublicIP = await getPublicIP();
console.log('userIP', userPublicIP)
//_____________________________ Get All Tabs ___________________________________

export const getYoutubeTabs = createAsyncThunk(
  "post/getYoutubeTabs",
  async (data) => {
    try {
      const response = await axios.post(
        `${baseURL}shorts`,
        data
      );
      console.log("response.data", response);
      return response;
    } catch (error) {
      throw error?.response?.data;
    }
  }
);


//_____________________________ Get Tabs Home Data ___________________________________

export const getYoutubeTabsHome = createAsyncThunk(
  "post/getYoutubeTabsHome",
  async (data) => {
    try {
      const response = await axios.post(
        `${baseURL}shorts`,
        data
      );
      console.log("response Home Data", response);
      return response;
    } catch (error) {
      throw error?.response?.data;
    }
  }
);


//_____________________________ Get Tabs Home Video ___________________________________

export const getYoutubeTabsVideos = createAsyncThunk(
  "post/getYoutubeTabsVideos",
  async (data) => {
    try {
      const response = await axios.post(
        `${baseURL}shorts`,
        data
      );
      // console.log("response Video Data-----", response);
      return response;
    } catch (error) {
      throw error?.response?.data;
    }
  }
);


//_____________________________ Get Tabs shorts Video ___________________________________

export const getYoutubeTabsShortsVideo = createAsyncThunk(
  "post/getYoutubeTabsShortsVideo",
  async (data) => {
    try {
      const response = await axios.post(
        `${baseURL}shorts`,
        data
      );
      // console.log("response Video Data-----", response);
      return response;
    } catch (error) {
      throw error?.response?.data;
    }
  }
);
//_____________________________ Get Community Tab ___________________________________

export const getYoutubeTabsCommunity = createAsyncThunk(
  "post/getYoutubeTabsCommunity",
  async (data) => {
    try {
      const response = await axios.post(
        `${baseURL}shorts`,
        data
      );
      // console.log("response Video Data-----", response);
      return response;
    } catch (error) {
      throw error?.response?.data;
    }
  }
);
//_____________________________ Get About Tabs ___________________________________

export const getYoutubeTabsAbout = createAsyncThunk(
  "post/getYoutubeTabsAbout",
  async (data) => {
    console.log("data😎😎", data)
    try {
      const response = await axios.post(
        `${baseURL}shorts`,
        data
      );
      // console.log("response Video Data-----", response);
      return response;
    } catch (error) {
      throw error?.response?.data;
    }
  }
);
//_____________________________ Get Event Tab ___________________________________

export const getYoutubeTabsEvent = createAsyncThunk(
  "post/getYoutubeTabsEvent",
  async (data) => {
    try {
      const response = await axios.post(
        `${baseURL}shorts`,
        data
      );
      // console.log("response Events data -----", response);
      return response;
    } catch (error) {
      throw error?.response?.data;
    }
  }
);
//_____________________________ Get Status Tab___________________________________

export const getYoutubeTabsStatus = createAsyncThunk(
  "post/getYoutubeTabsStatus",
  async (data) => {
    try {
      const response = await axios.post(
        `${baseURL}shorts`,
        data
      );

      return response;
    } catch (error) {
      throw error?.response?.data;
    }
  }
);
// export const getYoutubeTabslives = createAsyncThunk(
//   "post/getYoutubeTabslives",
//   async (data) => {
//     try {
//       const response = await axios.post(
//         `${baseURL}shorts`,
//         data
//       );
//       // console.log('livedata',response)
//       return response;
//     } catch (error) {
//       throw error.response.data;
//     }
//   }
// );
//---------------logout--------------------------------
export const logoutAsyncFun = createAsyncThunk(
  "post/logoutAsyncFun",
  async () => {
    console.log('---------');

  }
)


export const resetAsyncThunk = createAsyncThunk(
  "post/resetData",
  async () => {
    console.log('---------');

  }
)


const searchData = () => {
  try {
    let searchData = localStorage.getItem("searchData");
    if (searchData) searchData = JSON.parse(searchData);
    return searchData ?? [];
  } catch (error) {
    return {};
  }
};


//------------------------------Sort data-------------------------------

export const fetchShorts = createAsyncThunk('shorts/fetchShorts', async (data) => {
  const response =  data; // Replace with your API call to fetch data
  console.log('get send data short', response)
  return response?.data;
});

// export const sortShorts = createAsyncThunk('shorts/sortShorts', (data) => {
//   const sortedData = data.slice().sort((a, b) => a.viewCount - b.viewCount);
//   return sortedData;
// });

// export const resetData = () => ({
//   type: 'RESET_DATA',
// });
const searchYoutubeSlice = createSlice({
  name: "post",


  initialState: {
    searchData: [],
    tabsData: {},
    tabsHome: {},
    tabsVideo: {},
    tabsShorts: {},
    tabsCommunity: {},
    tabsAbout: {},
    tabsEvent: {},
    tabsStatus: {},
    // tablives: {},
    loading: false,
    error: null,
  },

  // reducers: {
  //   RESET_DATA: (state) => {
  //     state.searchData = searchData();
  //     state.tabsData = {};
  //     state.tabsHome = {};
  //     state.tabsVideo = {};
  //     state.tabsShorts = {};
  //     state.tabsCommunity = {};
  //     state.tabsAbout = {};
  //     state.loading = false;
  //     state.error = null;
  //   },
  // },

  extraReducers: (builder) => {
    builder
      //______________________________Search Chanel__________________________________

      .addCase(searchYoutubeChanel.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.successMessage = "";
      })
      .addCase(searchYoutubeChanel.fulfilled, (state, action) => {
        state.loading = false;
        localStorage.removeItem("searchData");
        state.tabsData = null;
        state.searchData = action?.payload;
        toast.success(action?.payload?.message);
        localStorage.setItem("searchData", JSON.stringify(action?.payload));
      })
      .addCase(searchYoutubeChanel.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload?.message;
        toast.error(action?.error?.message);
      })

      // //______________________________Get All Tabs__________________________________

      .addCase(getYoutubeTabs.pending, (state) => {
        state.loading = true;
        state.tabsData = null;
        state.error = null;
        state.successMessage = "";
      })
      .addCase(getYoutubeTabs.fulfilled, (state, action) => {
        state.loading = false;
        state.tabsData = action?.payload;
        toast.success(action?.payload?.message);
      })
      .addCase(getYoutubeTabs.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload?.message;
        toast.error(action?.error?.message);
      })

      //______________________________Get All Home__________________________________

      .addCase(getYoutubeTabsHome.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.successMessage = "";
      })
      .addCase(getYoutubeTabsHome.fulfilled, (state, action) => {
        state.loading = false;
        state.tabsHome = action?.payload;
        toast.success(action?.payload?.message);
      })
      .addCase(getYoutubeTabsHome.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload?.message;
        toast.error(action?.error?.message);
      })

      //______________________________Get All Videos Values__________________________________

      .addCase(getYoutubeTabsVideos.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.successMessage = "";
      })
      .addCase(getYoutubeTabsVideos.fulfilled, (state, action) => {
        state.loading = false;
        state.tabsVideo = action?.payload;
        toast.success(action?.payload?.message);
      })
      .addCase(getYoutubeTabsVideos.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload?.message;
        toast.error(action?.error?.message);
      })


      //______________________________Get All Shorts Videos Values__________________________________

      .addCase(getYoutubeTabsShortsVideo.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.successMessage = "";
      })
      .addCase(getYoutubeTabsShortsVideo.fulfilled, (state, action) => {
        state.loading = false;
        state.tabsShorts = action?.payload;
        toast.success(action?.payload?.message);
      })
      .addCase(getYoutubeTabsShortsVideo.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload?.message;
        toast.error(action?.error?.message);
      })

      //______________________________Get All Community Values__________________________________

      .addCase(getYoutubeTabsCommunity.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.successMessage = "";
      })
      .addCase(getYoutubeTabsCommunity.fulfilled, (state, action) => {
        state.loading = false;
        state.tabsCommunity = action?.payload;
        toast.success(action?.payload?.message);
      })
      .addCase(getYoutubeTabsCommunity.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload?.message;
        toast.error(action?.error?.message);
      })


      //______________________________Get All About Values__________________________________

      .addCase(getYoutubeTabsAbout.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.successMessage = "";
      })
      .addCase(getYoutubeTabsAbout.fulfilled, (state, action) => {
        state.loading = false;
        state.tabsAbout = action?.payload;
        toast.success(action?.payload?.message);
      })
      .addCase(getYoutubeTabsAbout.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload?.message;
        toast.error(action?.error?.message);
      })
      //______________________________Get All Event Values__________________________________
      .addCase(getYoutubeTabsEvent.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.successMessage = "";
      })
      .addCase(getYoutubeTabsEvent.fulfilled, (state, action) => {
        state.loading = false;
        state.tabsStatus = action?.payload;
        toast.success(action?.payload?.message);
      })
      .addCase(getYoutubeTabsEvent.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload?.message;
        toast.error(action?.error?.message);
      })

      //______________________________Get All status Values__________________________________
      .addCase(getYoutubeTabsStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.successMessage = "";
      })
      .addCase(getYoutubeTabsStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.tabsStatus = action?.payload;
        toast.success(action?.payload?.message);
      })
      .addCase(getYoutubeTabsStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload?.message;
        toast.error(action?.error?.message);
      })

      //______________________________Get All lives Values__________________________________
      // .addCase(getYoutubeTabslives.pending, (state) => {
      //   state.loading = true;
      //   state.error = null;
      //   state.successMessage = "";
      // })
      // .addCase(getYoutubeTabslives.fulfilled, (state, action) => {
      //   state.loading = false;
      //   state.tabslives = action?.payload;
      //   toast.success(action?.payload?.message);
      // })
      // .addCase(getYoutubeTabslives.rejected, (state, action) => {
      //   state.loading = false;
      //   state.error = action?.payload?.message;
      //   toast.error(action?.error?.message);
      // })
      //-----------------------------logout------------------------------
      .addCase(logoutAsyncFun.fulfilled, (state, action) => {
        state.searchData = null;
        state.tabsData = null;
      })
      .addCase(logoutAsyncFun.rejected, (state, action) => {
        state.searchData = null;
        state.tabsData = null;


      })

      //-----------------------------logout------------------------------
      .addCase(resetAsyncThunk.fulfilled, (state, action) => {
        state.searchData = searchData();
        state.tabsData = {};
        state.tabsHome = {};
        state.tabsVideo = {};
        state.tabsShorts = {};
        state.tabsCommunity = {};
        state.tabsAbout = {};
        state.tabsEvent = {};
        state.tabsStatus = {};
        state.loading = false;
        state.error = null;
      })
      .addCase(resetAsyncThunk.rejected, (state, action) => {
        state = state
      })

      //--------------------fetch data sort slice-------------
      .addCase(fetchShorts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchShorts.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchShorts.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});
export const { RESET_DATA } = searchYoutubeSlice.actions;

export default searchYoutubeSlice.reducer;
