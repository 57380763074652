import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const Play = () => {
  const { id } = useParams();
  const playlist = useSelector(
    (state) =>
      state?.searchYoutubeSlice?.tabsVideo?.data?.data?.[0]?.playlists
  );
  // console.log("🚀 ~ file: Play.jsx:13 ~ Play ~ playlist:", playlist)

  const selectedPlaylistItem = playlist?.find((item) => item.id === id);
  console.log('usama sasdj', selectedPlaylistItem)
  return (
    <div className='Play'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-8' id='videosection'>
            <video
              poster={selectedPlaylistItem?.thumbnailVideo?.thumbnails[0]?.url}
              controls
            >
              <source
                src={`https://www.youtube.com/embed/${selectedPlaylistItem?.thumbnailVideo?.id}`}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
            <p className='content'>{selectedPlaylistItem?.title}</p>

            <div className='channel_details'>
              <p><img src='/Images/youtube-logo.png' className='logo_img'/></p>
              <p className='name'>Channel name</p>
            </div>
          </div>
          <div className='col-md-4' id='VideosCollection'>
            <div className='Upper_portion'>
              <div className='flex_box'>
                <div>
                  <p className='title'>{selectedPlaylistItem?.title}</p>
                  <p className='channelName'>{selectedPlaylistItem?.authors[0]?.channelName}</p>
                  {selectedPlaylistItem?.publishedTimeText}
                  {/* <p>{selectedPlaylistItem?.videosNumber}</p> */}
                </div>
                <div className='Dropdown_box'>
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-button-dark-example1" variant="secondary">
                      <MoreVertIcon />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item>
                        Save Playlist to library
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
            <div className="collection">
              {selectedPlaylistItem?.firstVideos?.map((firstVideos) => (
                <div key={firstVideos.id} className='flex_box_2'>
                  <div>
                    <video
                      poster={firstVideos?.thumbnails[0]?.url}
                      controls
                    >
                      <source
                        src={`https://www.youtube.com/embed/${firstVideos[0]?.id}`}
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                    {/* <img src={firstVideos?.thumbnails[0]?.url} alt='' /> */}
                  </div>
                  <div className='flex_box_3'>
                  <p className='title'>{selectedPlaylistItem?.title}</p>
                    <p>{firstVideos.channelName}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Play;