import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { ApiRequests } from "../../service/ApiRequests";
import {
  catchAsync,
  handleLoadingErrorParamsForAsyncThunk,
  reduxToolKitCaseBuilder,
} from "../../helper/detectError.ts";

export const getCountsAsyncThunk = createAsyncThunk(
  "dashboard/getCountsAsyncThunk",
  catchAsync(async (__, { rejectWithValue }) => {
    const res = await ApiRequests.getCounts();
    return res?.data;
  })
);

const initialState = {
  //news states
  dashboardCounts: {
    users: 0,
    chats: 0,
    messages: 0,
    VerifiedUsers: 0,
    WaitingListUsers: 0,
    chatTypes: {
      daily: [],
      monthly: [],
      yearly: [],
    },
  },
  // manager states
  errors: {},
  loadings: {},
  errorMessages: {},
  errorCodes: {},
  paramsForThunk: {},
};

const blogSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setType(state, action) {
      state.type = action.payload;
    },
    setUser(state, action) {
      state.user = action.payload;
    },
    setTokens(state, action) {
      state.tokens = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCountsAsyncThunk.fulfilled, (state, action) => {
        state.dashboardCounts = action.payload;
      })
      .addMatcher(
        isAnyOf(...reduxToolKitCaseBuilder([getCountsAsyncThunk])),
        handleLoadingErrorParamsForAsyncThunk
      );
  },
});

export default blogSlice.reducer;
export const { setLoading, setType, setUser, setTokens } = blogSlice.actions;
