import React, { useEffect } from "react";
import ControlledCarousel from "../Components/Carousel";
import { useSelector } from "react-redux";


const Hometabs = () => {

  //------PlayList----
  const playlist = useSelector((state) => state?.searchYoutubeSlice);
  console.log("🚀 ~ file: Hometabs.js:10 ~ Hometabs ~ playlist:", playlist)
  const data =playlist?.tabsVideo?.data?.data?.[0]?.playlists
  
 
//-----Shorts---------
const shorts = useSelector(
  (state) => state?.searchYoutubeSlice?.tabsShorts?.data?.data[0].shorts || []
);
console.log("shorts home :::::::::", shorts)

  const loading = useSelector((state) => state?.searchYoutubeSlice)


  if (loading?.loading === true) {
    return <img src={"../Images/Animation.gif"} alt="" />
  }
  if (playlist?.loading === true) {
    return <img src={"../Images/Animation.gif"} alt="" />;
  }

  return (
    <div>
      <div>
        <ControlledCarousel data={data}  shorts={shorts} />
      </div>
    </div>
  );
};

export default Hometabs;
