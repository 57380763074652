import axios from "axios";
import { APIurls, baseURL } from "../constants";

const apiService = axios.create({
  baseURL,
  timeout: 20000,
  withCredentials: true,
});

apiService.interceptors.request.use((config) => {
  config.headers["Accept"] = "application/json, text/plain, */*";
  config.headers["Content-Type"] = "application/json";
  return config;
});

apiService.interceptors.response.use(
  (response) => response,
  async (error) => {
    try {
      console.log(error, "error");
      const originalRequest = error?.config;
      if (!(error instanceof axios.AxiosError)) throw error;
      if (error?.response?.data?.code !== 401) throw error;
      if (error?.response?.status !== 401) throw error;
      if (
        error?.request?.responseURL?.includes("auth") &&
        !error?.request?.responseURL?.includes(APIurls.authenticate)
      )
        throw error;
      if (originalRequest?._retry) throw error;
      originalRequest._retry = true;
      console.log("Refreshing tokens...", originalRequest);
      return await axios
        .post(
          `${baseURL}${APIurls.refreshTokens}`,
          {},
          {
            withCredentials: true,
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(async () => {
          await axios(originalRequest);
          return null;
        })
        .catch(async () => {
          try {
            await axios.post(
              `${baseURL}${APIurls.logout}`,
              {},
              { withCredentials: true }
            );
            // window.location.href = "/login";
          } catch (error) {}
          // window.location.href = "/login";
          return Promise.reject(error);
        });
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export default apiService;
