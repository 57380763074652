import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { ApiRequests } from "../../service/ApiRequests";
import {
  catchAsync,
  handleLoadingErrorParamsForAsyncThunk,
  reduxToolKitCaseBuilder,
} from "../../helper/detectError.ts";

export const getUsersAsyncThunk = createAsyncThunk(
  "user/getUsersAsyncThunk",
  catchAsync(async ({ params }, { rejectWithValue }) => {
    const res = await ApiRequests.getUsers(params);
    return res?.data;
  })
);
export const getUserAsyncThunk = createAsyncThunk(
  "user/getUserAsyncThunk",
  catchAsync(async ({ id }, { rejectWithValue }) => {
    const res = await ApiRequests.getUser(id);
    return res?.data;
  })
);
export const deleteUserAsyncThunk = createAsyncThunk(
  "user/deleteUserAsyncThunk",
  catchAsync(async ({ id, cb }, { rejectWithValue }) => {
    const res = await ApiRequests.deleteUser(id);
    if (cb && typeof cb === "function") cb();
    return res?.data;
  })
);
export const createUserAsyncThunk = createAsyncThunk(
  "user/createUserAsyncThunk",
  catchAsync(async ({ data, cb }, { rejectWithValue }) => {
    const res = await ApiRequests.creteUser(data);
    if (cb && typeof cb === "function") cb();
    return res?.data;
  })
);
export const updateUserAsyncThunk = createAsyncThunk(
  "user/updateUserAsyncThunk",
  catchAsync(async ({ data, id, cb }, { rejectWithValue }) => {
    const res = await ApiRequests.updateUser(id, data);
    if (cb && typeof cb === "function") cb();
    return res?.data;
  })
);

const initialState = {
  //news states
  users: {
    page: 1,
    limit: 10,
    totalPages: 1,
    totalResults: 0,
    results: [],
  },
  user: {},
  // manager states
  errors: {},
  loadings: {},
  errorMessages: {},
  errorCodes: {},
  paramsForThunk: {},
};

const blogSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setType(state, action) {
      state.type = action.payload;
    },
    setUser(state, action) {
      state.user = action.payload;
    },
    setTokens(state, action) {
      state.tokens = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsersAsyncThunk.fulfilled, (state, action) => {
        state.users = action.payload;
      })
      .addCase(getUserAsyncThunk.fulfilled, (state, action) => {
        state.user = action.payload;
      })
      .addMatcher(
        isAnyOf(
          ...reduxToolKitCaseBuilder([
            getUsersAsyncThunk,
            getUserAsyncThunk,
            deleteUserAsyncThunk,
            createUserAsyncThunk,
            updateUserAsyncThunk,
          ])
        ),
        handleLoadingErrorParamsForAsyncThunk
      );
  },
});

export default blogSlice.reducer;
export const { setLoading, setType, setUser, setTokens } = blogSlice.actions;
