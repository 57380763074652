import React from 'react'

export default function Footer() {
    return (
        <div>
            <div className='footer_section'>
                <div className='footer_content'>
                    <div className='footer_heading'>
                        <h5>We are not affiliated with Youtube.</h5>
                        <p>Ytube download Copyright © 2022</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
