import React, { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Dropdown from "react-bootstrap/Dropdown";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import CommentIcon from "@mui/icons-material/Comment";
import { useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

export default function Communitycards() {
  const [title, setTitle] = useState();
  const [isExpanded, setIsExpanded] = useState(false);
  const [itemClicked, setItemClicked] = useState(null);

  const toggleExpand = (item) => {
    setIsExpanded(!isExpanded);
    setItemClicked(item);
  };

  const community = useSelector(
    (state) => state?.searchYoutubeSlice?.tabsCommunity?.data?.data || []
  );

  console.log("community::::::::::::::😎😎😎😎", community);

  const loading = useSelector((state) => state?.searchYoutubeSlice);

  const titles =
    loading?.searchData?.data?.header?.c4TabbedHeaderRenderer.title;
  const name = loading?.searchData?.youtube?.name;
  const avatar = loading?.searchData?.youtube?.avatar;

  if (loading?.loading === true) {
    return <img src={"../Images/Animation.gif"} alt="" />;
  }

  return (
    <div className="communitycards">
      {community?.map((community, index) => {
        return community.community.map((item, itemIndex) => {
          return (
            <div key={itemIndex}>
              <div className="card">
                <div className="row">
                  <div className="col-lg-1 col-sm-2 col-3 d-flex justify-content-center">
                    <img
                      src={avatar || "/Images/download.jpg"}
                      className="images-logo"
                      alt="avatar"
                    />
                  </div>
                  <div className="col-lg-9 col-sm-8 col-7">
                    <div className="content_box">
                      <div className="flex-box">
                        <p className="channel_name">{name || "PCB"}</p>
                        <p className="time">{item?.date || "15 hours"}</p>
                      </div>
                      <div>
                        <div className="overlay">
                          <p
                            style={{ color: "black" }}
                            // href={`${it?.url}`}
                            // target="_blank"
                            // rel="noopener noreferrer"
                          >
                            {isExpanded && itemClicked == itemIndex
                              ? item?.contentText[0]?.text
                              : item?.contentText[0]?.text?.slice(0, 200)}...
                          </p>
                          {(isExpanded && itemClicked == itemIndex)
                              && <a
                            href={item?.contentText[0]?.text} 
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {item?.contentText[1]?.text
                              }
                          </a>}
                          {/* {item?.contentText?.map((it, i) => (
                            <div key={i}>
                              <p
                                style={{ color: "black" }}
                                // href={`${it?.url}`}
                                // target="_blank"
                                // rel="noopener noreferrer"
                              >
                                {isExpanded && itemClicked == itemIndex
                                  ? it?.text
                                  : it?.text?.slice(0, 200)}
                              </p>
                            </div>
                          ))} */}
                          {item?.contentText[0]?.text?.length && (
                            <p
                              className="button-hide"
                              onClick={() => toggleExpand(itemIndex)}
                            >
                              {isExpanded && itemClicked == itemIndex
                                ? "Show less"
                                : "Read more"}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-2 Dropdown_box">
                    <Dropdown className="float-end">
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <MoreVertIcon />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>

                <div className="box-2">
                  <div>
                    <p></p>
                  </div>
                  <div className="flex-box_3">
                    <div>
                      <img
                        src={item?.images[0]?.thumbnails[0]?.url}
                        className="video-img"
                        alt="video"
                      />
                    </div>
                  </div>
                  <div className="flex_box_4">
                    <div>
                      <Tooltip title="Like">
                        <IconButton>
                          <ThumbUpOffAltIcon /> {item?.likes}K
                        </IconButton>
                      </Tooltip>
                    </div>
                    <div className="">
                      <Tooltip title="DisLike">
                        <IconButton>
                          <ThumbDownOffAltIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                    <div className="">
                      <Tooltip title="Comment">
                        <IconButton>
                          <CommentIcon /> {item?.commentsCount}
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        });
      })}
    </div>
  );
}
