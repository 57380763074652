import React, { useEffect, useState } from "react";
// const GetChannelShorts = require('../YtubeShorts/GetChannelShorts')
import { Search } from "@mui/icons-material";
import { Card, Spinner } from "react-bootstrap";
import Home from "./Home";
import { ApiRequests } from "../service/ApiRequests";
import { getYoutubeShortsThunk } from "../store/slice/youtubeSlice";
import { useDispatch, useSelector } from "react-redux";
import { logoutAsyncFun, searchYoutubeChanel } from "../store/slice/dataSlice";
// import { searchYoutubeChanel } from "../service/api";
export default function SearchSection({ show, setShow }) {
  const [isLoading, setIsLoading] = useState(false);
  const [username, setUserName] = useState("");

  const [data, setData] = useState(null);
  const dispatch = useDispatch();
  // console.log(data);

  const searchData = useSelector((state) => state?.searchYoutubeSlice?.searchData);

  const { youtube } = searchData || {};


  // console.log("🚀 ~  myData:", searchData);

  //   const fetchData = async () => {
  //       setIsLoading(true);
  //       try {
  //           const res = await dispatch(getYoutubeShortsThunk({username}));
  //           console.log("Hello this is response", res.payload);
  //           setData(res.payload);
  //       } catch (error) {
  //           console.error("Error fetching data:", error);
  //       } finally {
  //           setIsLoading(false);
  //       }
  //   };

  // useEffect(() => {
  //   if (show) {
  //       fetchData();
  //   }
  // }, [show]);


  const handleSearchClick = async () => {
    // localStorage.removeItem("searchData");
    //   dispatch(logoutAsyncFun())
    setIsLoading(true);
    const data = {
      usernameOrUrl: username,
      portId: "shorts",
    };
    const response = await dispatch(searchYoutubeChanel(data));
    console.log("response 11", response);
    const res = response?.payload?.success
    // console.log('res', res)
    if (res === true) {

      setShow(show);
      setData(response);
    }
    // setData(null);
    // setShow(!show);
    setIsLoading(false);
  };
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearchClick();

    }
  };

  return (
    <div>
      <div className="main_heading">
        <h5>Download Youtube Videos & Shorts Videos</h5>
        <span>Best Video Downloader Ever</span>
        <div className="Input-section">
          <input
            onChange={(e) => setUserName(e.target.value)}
            onKeyDown={handleKeyPress}
            className="input_field"
            type="text"
            placeholder="Search Youtube Channel"
          />
          {isLoading ? (
            <Spinner size="sm" />
          ) : (
            <button
              disabled={!username}
              onClick={handleSearchClick}
              className="inp_btn"
            >
              {" "}
              <Search />{" "}
            </button>
          )}
        </div>


        <div className="chnl_card">
          <Card className="cardd">
            <div className="yt-container">
              <div className="img-div">
                <img
                  src={youtube?.avatar || "/Images/download.jpg"}
                  alt="img"
                  className="yt-image"
                />
              </div>
              <div className="box-content">
                <h1 className="yt-title">
                  {youtube?.name || "Genius Mind Zone"}
                </h1>
                <p>

                  @{youtube?.username || "geniusmindzone"}{" "}
                  {youtube?.subscribers || " . 17 subscribers. 23 "}
                  {youtube?.videosCountText?.[0]?.text || " "}{" "}
                  {youtube?.videosCountText?.[1]?.text || "videos"}
                </p>
              </div>
            </div>
          </Card>
        </div>


      </div>
      {/* {data && <Home data={data} />} */}
    </div>
  );
}
