import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Card from "react-bootstrap/Card";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Link } from "react-router-dom";

const ControlledCarousel = ({ data = [], shorts = [] }) => {
  console.log("🚀 ~ file: Carousel.jsx:12 ~ ControlledCarousel ~ shorts:", shorts)
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 2000, min: 1200 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 1199, min: 992 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 991, min: 768 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 767, min: 500 },
      items: 2,
    },
  };

  return (
    <div className="Carousel">
      <Carousel
        responsive={responsive}
        swipeable={true}
        draggable={true}
        showDots={false}
        autoPlaySpeed={8000}
      >
        {data &&
          data?.map((obj, i) => {
            // console.log("objjj", obj);
            return (
              <div key={i}>
                <Card className="card_div">
                  <div className="card_details">
                    <div className="Card_img">
                      <video
                        poster={obj?.thumbnailVideo?.thumbnails[0]?.url}
                        controls
                      >
                        <source
                          src={`https://www.youtube.com/embed/${obj?.id}`}
                          type="video/mp4"
                        />
                        Your browser does not support the video tag.
                      </video>
                      <Button className="download-btn">
                        {obj?.videoCount || '8 videos'} videos
                      </Button>
                      <Link to={`/play/${obj?.id}`} className="play_button">Play All</Link>
                    </div>
                    <div className="card_intro">
                      <div>
                        <h5>{obj?.title}</h5>

                        {/* <div className="views_detail">
                          <span>Views</span>
                          <span>Time</span>
                        </div> */}
                      </div>
                      <div className='Dropdown_box'>
                        <Dropdown>
                          <Dropdown.Toggle
                            id="dropdown-button-dark-example1"
                            variant="secondary"
                          >
                            <MoreVertIcon />
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item href="">Add to queue</Dropdown.Item>
                            <Dropdown.Item href="#/action-2">
                              Save to Watch Later
                            </Dropdown.Item>
                            <Dropdown.Item href="#/action-3">
                              Save to playlist
                            </Dropdown.Item>
                            <Dropdown.Item href="#/action-4">
                              Share
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
            );
          })}
      </Carousel>

      {shorts ? <Carousel
        responsive={responsive}
        swipeable={true}
        draggable={true}
        showDots={false}
        autoPlaySpeed={8000}
      >

        { shorts?.map((video, index) => {
          return (
            <Card className="card_div" key={index}>
              <div className="card_details">
                <div className="Card_img">
                  <a
                    href={`https://youtube.com/shorts/${video?.videoId}`}
                    target="_blank"
                  >
                    <img
                      alt="video-img"
                      src={
                        video?.thumbnails[0]?.url || "./Images/video-img.jpg"
                      }
                    ></img>
                    {/* <Button className="download-btn">
                      <DownloadForOfflineIcon />
                    </Button> */}
                  </a>
                </div>
                <div className="card_intro">
                  <div>
                    <h5>
                      {video?.title ||
                        "Saathiya official videos Kumar sanu youngveer"}
                    </h5>
                    <div className="views_detail">
                      <span>{`${video?.viewCount} viewCount` || "123k views"}</span>

                    </div>
                  </div>
                  <div className='Dropdown_box'>
                    <Dropdown>
                      <Dropdown.Toggle
                        id="dropdown-button-dark-example1"
                        variant="secondary"
                      >
                        <MoreVertIcon />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="">Add to queue</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">
                          Save to Watch Later
                        </Dropdown.Item>
                        <Dropdown.Item href="#/action-3">
                          Save to playlist
                        </Dropdown.Item>
                        <Dropdown.Item href="#/action-4">Share</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </Card>
          );
        })}

      </Carousel>
        : <div>Loadding..........</div>}
    </div>
  );
};

export default ControlledCarousel;
